html, body {
  padding: 0;
  margin: 0;
}


.mat-menu-content {
  button {
    &:nth-child(3) {
      display: none;
    }
  }
}